.aboutMe{
	text-align: left;
	margin: 10px;
}
.aboutMe h2{
	margin-top: 5px;
	font-weight: 300;
	color: #747474;
}
.profileInfo img{
	height: 200px;
	border-radius: 2px;
}
.profileInfo {
	display: flex;
	margin-top: 0px;
}
.profileInfo p{
	min-width: 300px;
	margin-top: 0px;
	margin-left: 20px;
	color: gray;
	font-weight: 300;
	line-height: 1.4em;
	font-size: 18px;
}
.About p{
  color: #747474;
}

@media only screen and (max-width: 479px) {
	.profileInfo{
		flex-wrap: wrap;
	}
	.profileInfo img{
		width: 100%;
		height: auto;
	}
	.profileInfo p{
		margin: 0px;
		margin-top: 10px;
	}

}